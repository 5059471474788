html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  overscroll-behavior: none;
}

#root {
  height: 100%;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none;
}
